import React from 'react'
import styled from 'styled-components'
import { linesRem, secondaryColor } from '~/utils/styling'
import PlaySvg from './assets/play.inline.svg'
import PauseSvg from './assets/pause.inline.svg'


const StyledButton = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: ${secondaryColor};
  margin-left: ${linesRem(.75)};
  display: flex;
  svg {
    margin: auto;
  }
`

interface ToggleButtonProps {
  playing: boolean
  onClick: (v: boolean) => void
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ playing, onClick }) => {
  return (
    <StyledButton onClick={() => onClick(!playing)}>
      {playing ? <PauseSvg /> : <PlaySvg />}
    </StyledButton>
  )
}

export default React.memo(ToggleButton)
import React from 'react'
import styled from 'styled-components'
import Audio from '~/components/Audio'
import { AudioDesc } from '~/types'

const StyledDiv = styled.div``

interface IAudioViewProps {
  audioUrl?: string
  audio?: AudioDesc
  audioList?: AudioDesc[]
}

const AudioView: React.FC<IAudioViewProps> = ({ audioUrl, audio, audioList }) => {
  if (audioList) {
    return (
      <>
        {audioList.map((it, index) => (
          <StyledDiv className="mb-1" key={`a${index}`}>
            <Audio src={it.url} title={it.title} filesize={it.size}/>
          </StyledDiv>
        ))}
      </>
    )
  } else if (audio) {
    return (
      <StyledDiv className="mb-1">
        <Audio src={audio.url} filesize={audio.size}/>
      </StyledDiv>
    )
  } else if (audioUrl) {
    <StyledDiv className="mb-1">
      <Audio src={audioUrl} />
    </StyledDiv>
  }
  return null
}

export default AudioView
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PlayButton from './PlayButton'
import Scrubber from './Scrubber'
import { IAudioState, IAudioActions } from '../types'
import { headerFontFamilyString } from '~/utils/styling'
import { useAppContext } from '~/contexts/appContext'
import uuid from 'uuid/v4'
import formatBytes from '~/utils/formatBytes'

export interface IAudioPlayerProps {
  title?: string 
  state: IAudioState
  actions: IAudioActions
}

const Container = styled.div`
  position: relative;
  background: #444;
  border-radius: 4px;
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .title {
    text-align: center;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 18px;
    right: 0;
    font-family: ${headerFontFamilyString};
    font-size: 14px;
    color: #b3b3b3;
    user-select: none;
  }
`

const Info = styled.div`
  position: absolute;
  bottom: 3px;
  right: 8px;
  font-size: 0.64rem;
  font-family: ${headerFontFamilyString};
  color: white;
  text-align: right;
  a {
    color: rgba(255,255,255,.45);
    &:link {
      color: rgba(255,255,255,.45);
    }
    &:visited {
      color: rgba(255,255,255,.45);
    }
    &:active {
      color: rgba(255,255,255,.9);
    }
    &:hover {
      color: rgba(255,255,255,.75);
    }
  }
`

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ title, state, actions, ...props }) => {

  const [id, setId] = useState(uuid())

  const { dispatch } = useAppContext()

  useEffect(() => {
    if (!actions) return
    dispatch({
      type: 'PLAYER_REG',
      player: {
        playerId: id,
        stop: () => {
          actions.pause()
        }
      },
    })
    return () => {
      dispatch({ type: 'PLAYER_UNREG', playerId: id })
    }
  }, [actions])

  useEffect(() => {
    if (state.playing) {
      dispatch({ type: 'PLAYER_START', playerId: id })
    }
  }, [state.playing])

  function onToggle() {
    actions.toggle()
  }

  return (
    <Container {...props}>
      {title && <div className="title">{title}</div>}
      <PlayButton playing={state.playing} onClick={onToggle}></PlayButton>
      <Scrubber
        canplay={state.canPlay}
        playing={state.playing}
        duration={state.duration}
        position={state.position}
        onChangePosition={pos => actions.setPosition(pos)}
      ></Scrubber>
      <Info>
        {state.downloadUrl && (
            <a href={state.downloadUrl} download>
            <svg style={{ marginRight: `3px`, position: `relative`, top: `1px` }} width="8" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 10h8V8.824H0V10zm8-6.47H5.714V0H2.286v3.53H0l4 4.117L8 3.53z" fill="rgba(255,255,255,.45)" fillRule="nonzero" /></svg>
              {state.filesize ? `Скачать [${formatBytes(state.filesize)}]` : `Скачать`}
            </a>
        )}</Info>
    </Container>
  )
}

export default AudioPlayer
import React, { useEffect, useState, createRef } from 'react'
// import styled from 'styled-components'
import Ratio from '~/components/Ratio'

interface IRutubeView {
  link: string,
  timeOffset?: number
}

const RutubeView: React.FC<IRutubeView> = ({ link, timeOffset }) => {
  const [showVideo, setShowVideo] = useState(false)
  const container = createRef<HTMLDivElement>()

  // const t = link.match(/\?t=(\d+)$/)[1]
  //https://rutube.ru/video/961f874af554e50afbdca797edc3cfba/?r=plwd
  const id = link.match(/\/([0-9a-f]+?)\/?(\?.*)?$/)[1]

  const src = timeOffset ? `https://rutube.ru/play/embed/${id}?t=${timeOffset}` :  `https://rutube.ru/play/embed/${id}`

  useEffect(() => {
    if (
      window &&
      'IntersectionObserver' in window &&
      container &&
      container.current
    ) {
      const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: '100px 0px',
        threshold: 0.25,
      })
      function onVideoIntersection(entries: any) {
        if (!entries || entries.length <= 0) return
        if (entries[0].isIntersecting) {
          setShowVideo(true)
          videoObserver.disconnect()
        }
      }
      videoObserver.observe(container.current)
      return () => {
        videoObserver.disconnect()
      }
    } else {
      setShowVideo(true)
    }
  }, [container])

  return (
    <div className="mb-1" ref={container}>
      {/* @ts-ignore */}
      <Ratio style={{ background: 'black' }}>
        {showVideo ? (
          <iframe
            // width="720"
            // height="405"
            src={src}
            frameBorder="0"
            allow="clipboard-write; autoplay"
            //@ts-ignore
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        ) : // <iframe
        //   src={`https://www.Rutube.com/embed/${id}`}
        //   //@ts-ignore
        //   frameBorder="0"
        //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        //   allowFullScreen
        // ></iframe>
        undefined}
      </Ratio>
    </div>
  )
}

export default RutubeView

import React, { useEffect, useState, createRef } from 'react'
// import styled from 'styled-components'
import Ratio from '~/components/Ratio'

interface IVkView {
  link: string
}

const VkView: React.FC<IVkView> = ({ link }) => {
  const [showVideo, setShowVideo] = useState(false)
  const container = createRef<HTMLDivElement>()

  // const t = link.match(/\?t=(\d+)$/)[1]
  // const id = link.match(/\/([^/]+?)$/)[1]
  const l = link.split('_')

  useEffect(() => {
    if (window && 'IntersectionObserver' in window && container && container.current) {   
      const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: '100px 0px',
        threshold: 0.25
      })
      function onVideoIntersection(entries: any) {
        if (!entries || entries.length <= 0) return
        if (entries[0].isIntersecting) {
          setShowVideo(true)
          videoObserver.disconnect()
        }
      }
      videoObserver.observe(container.current)
      return () => {
        videoObserver.disconnect()
      }
    } else {
      setShowVideo(true)
    }
  }, [container])

  return (
    //https://vk.com/video711346385_456239023
    <div className="mb-1" ref={container}>
      <Ratio style={{ background: 'black' }}>
        {showVideo && l && l.length && l.length === 3 ? 
          <iframe 
            src={`https://vk.com/video_ext.php?oid=${l[0]}&id=${l[1]}&hash=${l[2]}`}
            //@ts-ignore
            frameborder="0"
            allowfullscreen
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture">
          </iframe>
          : undefined }
      </Ratio>
    </div>
  )
}

export default VkView
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { headerFontFamilyString, primaryColor } from '~/utils/styling'
import ShareSvg from './assets/share.inline.svg'
import FbSvg from './assets/fb.inline.svg'
import VkSvg from './assets/vk.inline.svg'
import CopySvg from './assets/copy.inline.svg'
import { SiteUrlForSharedPanelQuery } from '~/types'
import { withStyles } from '@material-ui/core/styles'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'

const StyledMenu = withStyles({
  paper: {
    backgroundColor: `white`,
    boxShadow: `0 3px 7px #ccc`
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: headerFontFamilyString,
    padding: `3px 10px`,
    lineHeight: 1.4,
    backgroundColor: `white`,
    '& .MuiListItemIcon-root': {
      minWidth: `32px`
    },
    '& .MuiListItemText-primary': {
      fontFamily: headerFontFamilyString,
      padding: `0`,
      lineHeight: 1.2,
    },
    '&:focus': {
      backgroundColor: `#e5d9f2`,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        fontFamily: headerFontFamilyString,
        // color: `white`,
      },
    },
  },
}))(MenuItem);

const Wrapper = styled.div`
  display: inline-block;
`

const Button = styled.div``

interface ISharePanelProps {
  link: string
}

const SharePanel: React.FC<ISharePanelProps> = ({link}) => {
  const data: SiteUrlForSharedPanelQuery = useStaticQuery(graphql`
    query siteUrlForSharedPanel {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const [copied, setCopied] = useState(false)

  const url = data.site.siteMetadata.siteUrl + link

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  ///
  const onFbShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank')
    handleClose()
  }

  const onVkShare = () => {
    window.open(`https://vk.com/share.php?url=${encodeURIComponent(url)}`, '_blank')
    handleClose()
  }

  const onCopy = () => {
    setCopied(true)
    // handleClose()
  }

  useEffect(() => {
    if (copied) {
      handleClose()
      setCopied(false)
    }
  }, [copied])

  return (
    <Wrapper>
      <Tooltip arrow title="Поделиться ссылкой" placement="top" enterDelay={300}>
        <Button onClick={handleClick}>
          <ShareSvg></ShareSvg>
        </Button>
      </Tooltip>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={onFbShare}>
          <ListItemIcon>
            <FbSvg></FbSvg>
          </ListItemIcon>
          <ListItemText primary="Facebook" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onVkShare}>
          <ListItemIcon>
            <VkSvg></VkSvg>
          </ListItemIcon>
          <ListItemText primary="Вконтакте" />
        </StyledMenuItem>
        <CopyToClipboard text={url} onCopy={onCopy}>
          <StyledMenuItem>
            <ListItemIcon>
              <CopySvg></CopySvg>
            </ListItemIcon>
            <ListItemText primary="Копировать ссылку" />
          </StyledMenuItem>
        </CopyToClipboard>
      </StyledMenu>
    </Wrapper>
  )
}

export default SharePanel
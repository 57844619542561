import React from 'react'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { headerFontFamilyString, bodyFontFamilyString, linesRem } from '~/utils/styling'
import sulgify from 'slugify'
import tagColorByLabel from '~/utils/tagColor'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customWidth: {
      fontFamily: bodyFontFamilyString,
      fontSize: '.64rem',
      textAlign: 'center',
      fontStyle: 'italic',
      maxWidth: 250,
      opacity: .75
    },
  })
)

const TagDiv = styled.span<{ color: string; opacity: number }>`
  position: relative;
  background: ${({ color }) => (color ? color : `#808080`)};
  color: white;
  font-family: ${headerFontFamilyString};
  font-size: 0.75rem;
  line-height: 0.85rem;
  padding: ${linesRem(0.1)} ${linesRem(0.3)} ${linesRem(0.1)} ${linesRem(0.5)};
  border-radius: 1rem;
  opacity: ${({ opacity }) => (opacity ? opacity : 0.5)};
  margin-right: ${linesRem(0.2)};
  margin-bottom: ${linesRem(0.2)};
  user-select: none;
  font-style: normal;
  cursor: pointer;
  transition: opacity 0.5s;
  &:hover {
    opacity: 1;
  }
  :before {
    content: '';
    position: absolute;
    background: #f1f1f1;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    top: calc(50% - 2px);
    left: 5px;
  }
  .count {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    padding: 1px 7px;
    margin-left: 7px;
    margin-right: -${linesRem(0.1)};
    font-size: 0.6rem;
    line-height: 0.6rem;
    /* box-shadow: inset 0 -1px 0 rgba(0,0,0,.1); */
    color: rgba(255, 255, 255, 0.7);
  }
`

interface ITagProps {
  color?: string
  label: string
  count?: number
  opacity?: number
}

const tooltips: {[key: string]: string} = {
  'слова': 'сказанные по определенному случаю',
  'online': 'записи сделанные в прямом эфире',
  'о сердечной молитве': '«Царствие Небесное в наших сердцах». Поучения из моей библиотеки.',
}

const Tag: React.FC<ITagProps> = ({ color, label, count, opacity }) => {
  const slug = sulgify(label)
  function onClick(event: React.MouseEvent) {
    navigate(`/blog/tags/${slug}`)
  }

  const classes = useStyles()

  const TagJsx = <TagDiv
        opacity={opacity}
        color={color || tagColorByLabel(label)}
        onClick={onClick}
        // style={{ cursor: tooltips[label] ? 'help' : 'default' }}
      >
        {label}
        {count && <span className="count">{count}</span>}
      </TagDiv>
  return tooltips[label] ? (
    <Tooltip
      arrow
      title={tooltips[label]}
      placement="top"
      enterDelay={700}
      classes={{ tooltip: classes.customWidth }}
    >
      {TagJsx}
    </Tooltip>
  ) : (
    TagJsx
  )
}

export default Tag
